import { ContentSectionType } from "./../types/strapi/ContentSectionType";
import { ContactUsSectionType } from "./../types/strapi/ContactUsSectionType";
import { RSETitleSectionType } from "./../types/strapi/RSETitleSectionType";
import { TwoColorColumnsSectionType } from "./../types/strapi/TwoColorColumnsSectionType";
import { SimplesSectionType } from "./../types/strapi/SimplesSectionType";
/* eslint-disable indent */
import { FooterSectionType } from "./../types/strapi/FooterSectionType";
import { NavigationBarType } from "./../types/strapi/NavigationBarType";
import { StrapiApi } from "./strapiApi";
import {
  CardsSectionType,
  PageBaseType,
  PageSectionType,
  SectionsCollectionType,
  SectionStrapiTypeEnum,
  SectionsType,
  SectionTypeEnum,
  LinksSectionType,
  LogosSectionType,
  RatingsSectionType,
  TitlesSectionType,
  FoldableSectionType,
  TransfertsSectionType,
  MapsCardSectionType,
  LargeCardsSectionType,
  ImageBannerSectionType,
  NewsLetterBannerType,
  EcologyBannerType,
  SectionsSingleType,
  MobileAppBannerType,
  CityType,
} from "../types";
import NodeCache from "node-cache";
import { v4 as uuidv4 } from "uuid";
import { DatasSectionType } from "../types/strapi/DatasSectionType";
//console.log(process.env);
export const memCache = new NodeCache({
  stdTTL: parseInt(process.env.NEXT_PUBLIC_CACHE_EXPIRATION as string),
  checkperiod: 0,
});

export function toCache<T>(o: T, filepath: string) {
  memCache.set(filepath, JSON.stringify(o));
}

export function fromCache<T>(filepath: string): T | null {
  const data = memCache.get(filepath) as string;
  return data && data.length > 0 ? JSON.parse(data) : null;
}

// export function toCache<T>(o: T, filepath: string) {
//   fs.writeFileSync(filepath, JSON.stringify(o));
// }

// export function fromCache<T>(filepath: string): T | null {
//   try {
//     return JSON.parse(fs.readFileSync(filepath, { encoding: 'utf8' })) as T;
//   } catch (e) {
//     return null;
//   }
// }

export async function getNavBarCache(): Promise<
  PageSectionType<NavigationBarType>
> {
  const filepath = "navBar";
  let data = fromCache<NavigationBarType>(filepath);
  if (!data || !data.links) {
    data = await StrapiApi.single_types.navigationBar("fr");
    toCache<NavigationBarType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.NAVIGATION_BAR,
    section: data,
  };
}

export async function getFooterCache(): Promise<
  PageSectionType<FooterSectionType>
> {
  const filepath = "footer";
  let data = fromCache<FooterSectionType>(filepath);
  if (!data || !data.columns) {
    data = await StrapiApi.single_types.footer("fr");
    toCache<FooterSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.FOOTER,
    section: data,
  };
}

export async function getNewsletterBannerCache(): Promise<
  PageSectionType<NewsLetterBannerType>
> {
  const filepath = "newsletter";
  let data = fromCache<NewsLetterBannerType>(filepath);
  if (!data) {
    data = await StrapiApi.single_types.newsletterBanner("fr");
    toCache<NewsLetterBannerType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.NEWSLETTERS_BANNER,
    section: data,
  };
}

export async function getContactUsCache(): Promise<
  PageSectionType<ContactUsSectionType>
> {
  const filepath = "contact-us";
  let data = fromCache<ContactUsSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.single_types.contactUs("fr");
    toCache<ContactUsSectionType>(data, filepath);
  }
  return { id: uuidv4(), name: SectionTypeEnum.CONTACT_US, section: data };
}

export async function getEcologyBannerCache(): Promise<
  PageSectionType<EcologyBannerType>
> {
  const filepath = "ecology";
  let data = fromCache<EcologyBannerType>(filepath);
  if (!data) {
    data = await StrapiApi.single_types.ecologyBanner("fr");
    toCache<EcologyBannerType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.ECOLOGY_BANNER,
    section: data,
  };
}

export async function getMobileAppBannerCache(): Promise<
  PageSectionType<MobileAppBannerType>
> {
  const filepath = "mobile-app-banner";
  let data = fromCache<MobileAppBannerType>(filepath);
  if (!data) {
    data = await StrapiApi.single_types.mobileAppBanner("fr");
    toCache<MobileAppBannerType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.MOBILE_APP_BANNER,
    section: data,
  };
}

export async function getSectionSimplesCache(
  slug: string
): Promise<PageSectionType<SimplesSectionType>> {
  const filepath = `${SectionTypeEnum.SIMPLES_SECTION}-${slug}`;
  let data = fromCache<SimplesSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.simplesSection("fr", slug);
    toCache<SimplesSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.SIMPLES_SECTION,
    section: data,
  };
}

export async function getSectionCardsCache(
  slug: string
): Promise<PageSectionType<CardsSectionType>> {
  const filepath = `${SectionTypeEnum.CARDS_SECTION}-${slug}`;
  let data = fromCache<CardsSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.cardsSection("fr", slug);
    toCache<CardsSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.CARDS_SECTION,
    section: data,
  };
}

export async function getSectionDatasCache(
  slug: string
): Promise<PageSectionType<DatasSectionType>> {
  const filepath = `${SectionTypeEnum.DATAS_SECTION}-${slug}`;
  let data = fromCache<DatasSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.datasSection("fr", slug);
    toCache<DatasSectionType | null>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.DATAS_SECTION,
    section: data as DatasSectionType,
  };
}

export async function getSectionContentsCache(
  slug: string
): Promise<PageSectionType<ContentSectionType>> {
  const filepath = `${SectionTypeEnum.CONTENTS_SECTION}-${slug}`;
  let data = fromCache<ContentSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.contentSection("fr", slug);
    toCache<ContentSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.CONTENTS_SECTION,
    section: data,
  };
}

export async function getSectionMapsCardsCache(
  slug: string
): Promise<PageSectionType<MapsCardSectionType>> {
  const filepath = `${SectionTypeEnum.MAPS_CARD_SECTION}-${slug}`;
  let data = fromCache<MapsCardSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.mapsCardSection("fr", slug);
    toCache<MapsCardSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.MAPS_CARD_SECTION,
    section: data,
  };
}

export async function getSectionTransfertsCache(
  slug: string
): Promise<PageSectionType<TransfertsSectionType>> {
  const filepath = `${SectionTypeEnum.TRANSFERTS_SECTION}-${slug}`;
  let data = fromCache<TransfertsSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.transfertSection("fr", slug);
    toCache<TransfertsSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.TRANSFERTS_SECTION,
    section: data,
  };
}

export async function getSectionFoldablesCache(
  slug: string
): Promise<PageSectionType<FoldableSectionType>> {
  const filepath = `${SectionTypeEnum.FOLDABLE_SECTION}-${slug}`;
  let data = fromCache<FoldableSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.foldableSection("fr", slug);
    toCache<FoldableSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.FOLDABLE_SECTION,
    section: data,
  };
}

export async function getSectionTitlesCache(
  slug: string
): Promise<PageSectionType<TitlesSectionType>> {
  const filepath = `${SectionTypeEnum.TITLES_SECTION}-${slug}`;
  let data = fromCache<TitlesSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.titlesSection("fr", slug);
    toCache<TitlesSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.TITLES_SECTION,
    section: data,
  };
}
export async function getSectionRatingsCache(
  slug: string
): Promise<PageSectionType<RatingsSectionType>> {
  const filepath = `${SectionTypeEnum.RATINGS_SECTION}-${slug}`;
  let data = fromCache<RatingsSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.ratingsSection("fr", slug);
    toCache<RatingsSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.RATINGS_SECTION,
    section: data,
  };
}

export async function getSectionLogosCache(
  slug: string
): Promise<PageSectionType<LogosSectionType>> {
  const filepath = `${SectionTypeEnum.LOGOS_SECTION}-${slug}`;
  let data = fromCache<LogosSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.logosSection("fr", slug);
    toCache<LogosSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.LOGOS_SECTION,
    section: data,
  };
}

export async function getSectionLinksCache(
  slug: string
): Promise<PageSectionType<LinksSectionType>> {
  const filepath = `${SectionTypeEnum.LINKS_SECTION}-${slug}`;
  let data = fromCache<LinksSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.linksSection("fr", slug);
    toCache<LinksSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.LINKS_SECTION,
    section: data,
  };
}

export async function getSectionLargeCardsCache(
  slug: string
): Promise<PageSectionType<LargeCardsSectionType>> {
  const filepath = `${SectionTypeEnum.LARGE_CARDS_SECTION}-${slug}`;
  let data = fromCache<LargeCardsSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.largeCardsSection("fr", slug);
    toCache<LargeCardsSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.LARGE_CARDS_SECTION,
    section: data,
  };
}

export async function getSectionColorColumnsCache(
  slug: string
): Promise<PageSectionType<TwoColorColumnsSectionType>> {
  const filepath = `${SectionTypeEnum.TWO_COLOR_COLUMNS_SECTION}-${slug}`;
  let data = fromCache<TwoColorColumnsSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.colorColumnsSection("fr", slug);
    toCache<TwoColorColumnsSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.TWO_COLOR_COLUMNS_SECTION,
    section: data,
  };
}

export async function getSectionRSETitlesCache(
  slug: string
): Promise<PageSectionType<RSETitleSectionType>> {
  const filepath = `${SectionTypeEnum.RSE_TITLES_SECTION}-${slug}`;
  let data = fromCache<RSETitleSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.rseTitlesSection("fr", slug);
    toCache<RSETitleSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.RSE_TITLES_SECTION,
    section: data,
  };
}

export async function getSectionImageBannersCache(
  slug: string
): Promise<PageSectionType<ImageBannerSectionType>> {
  const filepath = `${SectionTypeEnum.IMAGE_BANNERS}-${slug}`;
  let data = fromCache<ImageBannerSectionType>(filepath);
  if (!data) {
    data = await StrapiApi.collection_types.imageBanner("fr", slug);
    toCache<ImageBannerSectionType>(data, filepath);
  }
  return {
    id: uuidv4(),
    name: SectionTypeEnum.IMAGE_BANNERS,
    section: data,
  };
}

async function getSectionCache(
  type: SectionTypeEnum,
  slug: string
): Promise<PageSectionType<SectionsCollectionType> | null> {
  switch (type) {
    case SectionTypeEnum.CARDS_SECTION:
      return getSectionCardsCache(slug);
    case SectionTypeEnum.DATAS_SECTION:
      return getSectionDatasCache(slug);
    case SectionTypeEnum.CONTENTS_SECTION:
      return getSectionContentsCache(slug);
    case SectionTypeEnum.SIMPLES_SECTION:
      return getSectionSimplesCache(slug);
    case SectionTypeEnum.LINKS_SECTION:
      return getSectionLinksCache(slug);
    case SectionTypeEnum.LOGOS_SECTION:
      return getSectionLogosCache(slug);
    case SectionTypeEnum.RATINGS_SECTION:
      return getSectionRatingsCache(slug);
    case SectionTypeEnum.TITLES_SECTION:
      return getSectionTitlesCache(slug);
    case SectionTypeEnum.FOLDABLE_SECTION:
      return getSectionFoldablesCache(slug);
    case SectionTypeEnum.TRANSFERTS_SECTION:
      return getSectionTransfertsCache(slug);
    case SectionTypeEnum.MAPS_CARD_SECTION:
      return getSectionMapsCardsCache(slug);
    case SectionTypeEnum.LARGE_CARDS_SECTION:
      return getSectionLargeCardsCache(slug);
    case SectionTypeEnum.IMAGE_BANNERS:
      return getSectionImageBannersCache(slug);
    case SectionTypeEnum.TWO_COLOR_COLUMNS_SECTION:
      return getSectionColorColumnsCache(slug);
    case SectionTypeEnum.RSE_TITLES_SECTION:
      return getSectionRSETitlesCache(slug);
    default:
      return null;
  }
}

export async function getSectionSingleTypeCache(
  type: SectionTypeEnum,
  locale = "fr"
): Promise<PageSectionType<SectionsSingleType> | null> {
  switch (type) {
    case SectionTypeEnum.MOBILE_APP_BANNER:
      return getMobileAppBannerCache();
    case SectionTypeEnum.NAVIGATION_BAR:
      return getNavBarCache();
    case SectionTypeEnum.FOOTER:
      return getFooterCache();
    case SectionTypeEnum.ECOLOGY_BANNER:
      return getEcologyBannerCache();
    case SectionTypeEnum.NEWSLETTERS_BANNER:
      return getNewsletterBannerCache();
    case SectionTypeEnum.CONTACT_US:
      return getContactUsCache();
    default:
      return null;
  }
}

export async function getAllSectionFromPageCache(
  page: PageBaseType,
  slugPrefix = ""
): Promise<(PageSectionType<SectionsType> | null)[]> {
  return Promise.all(
    page.sections.map(async (section) => {
      if (!section) return null;
      if (section.strapiType == SectionStrapiTypeEnum.SINGLE) {
        return getSectionSingleTypeCache(section.typeName);
      }
      let res =
        section.slug && section.slug.length > 0
          ? await getSectionCache(section.typeName, section.slug)
          : null;
      if ((!res || !res.section) && slugPrefix && slugPrefix.length > 0) {
        res = await getSectionCache(section.typeName, `${slugPrefix}default`);
      }
      if (!res || !res.section) {
        res = await getSectionCache(section.typeName, "default");
      }
      return res;
    })
  );
}

const CITIES_CACHENAME = "cities";

export async function getAllCitiesFromCache(): Promise<CityType[]> {
  const cached: CityType[] | null = fromCache(CITIES_CACHENAME);
  if (cached) {
    console.log("CITIES IN CACHE", cached.length);
    return cached;
  }
  console.log("NO CITIES IN CACHE, retrieve form STRAPI and store");
  const cities = await StrapiApi.collection_types.allCities([], "fr");
  cities.forEach((city) => toCache<CityType>(city, `city-${city.slug}`));
  toCache(cities, CITIES_CACHENAME);
  return cities;
}
